export const Endpoint = process.env.REACT_APP_API_URL
export const prefix = 'tracking'
export const trackingConstants = {    
    API_GETALL: `${Endpoint}/${prefix}`,
    API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
    API_CREATE: `${Endpoint}/${prefix}/create`,
  
    INSERT_SUCCESS: 'TRACKING_INSERT_SUCCESS',
    INSERT_FAILURE: 'TRACKING_INSERT_FAILURE',

    GET_ALL:    'TRACKING_GET_ALL',
    GET_DATA:   'TRACKING_GET_DATA',
    GET_SELECT: 'TRACKING_GET_SELECT',
    GET_FAILURE:'TRACKING_GET_FAILURE',
    CLEAR_DATA: 'TRACKING_CLEAR_DATA'
}
