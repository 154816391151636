export const Endpoint = process.env.REACT_APP_API_URL;
export const prefix = 'report';
export const reportConstants = {
  API_GETBYID: `${Endpoint}/${prefix}`,
  API_GETALL: `${Endpoint}/${prefix}`,
  API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
  API_CREATE: `${Endpoint}/${prefix}/create`,
  API_DELETE: `${Endpoint}/${prefix}/delete`,
  API_UPDATE: `${Endpoint}/${prefix}/update`,
  API_UPDATE_TRACK: `${Endpoint}/${prefix}/update/tracking`,
  API_APPROVE: `${Endpoint}/${prefix}/approve`,

  //REPORT-API-URL:
  API_DELIVERY_APPROVE_REQUEST: `${Endpoint}/${prefix}/deliveryApprovalRequest`, //*/report/deliveryApprovalRequest
  API_TOTAL_DELIVERY_REPORT: `${Endpoint}/${prefix}/totaldelivery`, //*/report/deliveryApprovalRequest
  API_CUSTOMER_REPORT: `${Endpoint}/${prefix}/customers`, //*/report/customers
  API_PICKLIST_REPORT: `${Endpoint}/${prefix}/picklist`, //*/report/picklist
  API_BILLING_REPORT: `${Endpoint}/${prefix}/billing`, //*/report/billing
  API_CLAIM_REPORT: `${Endpoint}/${prefix}/claim`, //*/report/claim
  API_SALES_REPORT: `${Endpoint}/${prefix}/sales`, //*/report/sales
  API_REFERENCE_REPORT: `${Endpoint}/${prefix}/reference`, //*/report/reference
  API_RAWDATA_REPORT: `${Endpoint}/${prefix}/rawdata`, //*/report/rawdata

  //GET REPORT:
  GET_DELIVERY_APPROVE_REQUEST_REPORT: 'DELIVERY_APPROVE_REQUEST_REPORT',
  GET_TOTAL_DELIVERY_REPORT: 'TOTAL_DELIVERY_REPORT',
  GET_BILLING_REPORT: 'GET_BILLING_REPORT',
  GET_CLAIM_REPORT: 'GET_CLAIM_REPORT',

  //8***

  INSERT_SUCCESS: 'REPORT_INSERT_SUCCESS',
  INSERT_FAILURE: 'REPORT_INSERT_FAILURE',

  UPDATE_SUCCESS: 'REPORT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'REPORT_UPDATE_FAILURE',

  DELETE_SUCCESS: 'REPORT_DELETE_SUCCESS',
  DELETE_FAILURE: 'REPORT_DELETE_FAILURE',

  GET_ALL: 'REPORT_GET_ALL',
  GET_DATA: 'REPORT_GET_DATA',
  GET_SELECT: 'REPORT_GET_SELECT',

  GET_FAILURE: 'REPORT_GET_FAILURE',
  CLEAR_DATA: 'REPORT_CLEAR_DATA',
};
