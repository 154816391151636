import { usersConstants as constants } from '../../constants/users'

const initialState = {
  allData: [],
  allRole: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  isError: null,
  error: ''
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ALL:
      return { ...state, allData: action.data, allRole: action.roles}
    case constants.GET_DATA:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case constants.GET_SELECT:
      return { ...state, selectedData: action.selectedData }
    case constants.GET_SALE:
      return { ...state, data: action.data}

    case constants.INSERT_SUCCESS:
    case constants.UPDATE_SUCCESS:
    case constants.DELETE_SUCCESS:
      return { ...state, isError: false, error: '' }

    case constants.GET_FAILURE:
    case constants.INSERT_FAILURE:
    case constants.UPDATE_FAILURE:
    case constants.DELETE_FAILURE:
        return { ...state, isError: true, error: action.error}

    case constants.CLEAR_DATA:
      return { ...state, isError: null, error: '', selectedData: null  }
    default:
      return { ...state }
  }
}
export default userReducer
