export const Endpoint = process.env.REACT_APP_API_URL
export const prefix = 'channel'
export const channelConstants = {
    API_GETBYID: `${Endpoint}/${prefix}`,
    API_GETALL: `${Endpoint}/${prefix}`,
    API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
    API_CREATE: `${Endpoint}/${prefix}/create`,
    API_DELETE: `${Endpoint}/${prefix}/delete`,
    API_UPDATE: `${Endpoint}/${prefix}/update`,
  
    INSERT_SUCCESS: 'CHANNEL_INSERT_SUCCESS',
    INSERT_FAILURE: 'CHANNEL_INSERT_FAILURE',

    UPDATE_SUCCESS: 'CHANNEL_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CHANNEL_UPDATE_FAILURE',
  
    DELETE_SUCCESS: 'CHANNEL_DELETE_SUCCESS',
    DELETE_FAILURE: 'CHANNEL_DELETE_FAILURE',
    
    GET_ALL: 'CHANNEL_GET_ALL',
    GET_DATA: 'CHANNEL_GET_DATA',
    GET_SELECT: 'CHANNEL_GET_SELECT',

    GET_FAILURE: 'CHANNEL_GET_FAILURE',
    CLEAR_DATA: 'CHANNEL_CLEAR_DATA'
}
