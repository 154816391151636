import { ordersConstants as constants } from '../../constants/orders';

const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  orderId: null,
  selectedData: null,
  isError: null,
  error: '',

  selectedCustomer: {},
  selectedShipping: {},
  selectedChannel: {},
  isLoading: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ALL:
      return { ...state, allData: action.data };
    case constants.GET_DATA:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case constants.GET_SELECT:
      return { ...state, selectedData: action.selectedData };

    case constants.INSERT_SUCCESS:
    case constants.UPDATE_SUCCESS:
    case constants.DELETE_SUCCESS:
      return { ...state, isError: false, error: '', orderId: action.orderId };

    case constants.GET_FAILURE:
    case constants.INSERT_FAILURE:
    case constants.UPDATE_FAILURE:
    case constants.DELETE_FAILURE:
      return { ...state, isError: true, error: action.error };
    case constants.ADD_PRODUCT:
      return { ...state, addProduct: action.data };
    case constants.CLEAR_DATA:
      return { ...state, isError: null, error: '', selectedData: null, orderId: null };
    case constants.SET_DISABLE_SHIPPING:
      return { ...state, disableShippingStatus: action.status };
    case constants.LOADING:
      return { ...state, isLoading: action.isLoading };
    default:
      return { ...state };
  }
};
export default orderReducer;
