// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import navbar from './navbar';
import layout from './layout';

import shippingReducer from './masters/shipping';
import channelReducer from './masters/channel';
import boxSizeReducer from './masters/boxSize';
import userReducer from './masters/users';
import permissionReducer from './masters/permission';
import customerReducer from './masters/customers';
import productReducer from './masters/products';
import trackingReducer from './apps/tracking';
import orderReducer from './apps/orders';
import reportReducer from './apps/reports';
import rolesReducer from './masters/role';
import dashboardReducer from './dashboard';
import bankReducer from './masters/bank';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  shippingReducer,
  channelReducer,
  boxSizeReducer,
  userReducer,
  customerReducer,
  productReducer,
  trackingReducer,
  orderReducer,
  reportReducer,
  permissionReducer,
  rolesReducer,
  dashboardReducer,
  bankReducer,
});

export default rootReducer;
