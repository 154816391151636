export const Endpoint = process.env.REACT_APP_API_URL
export const prefix = 'shipping'
export const shippingConstants = {
    API_GETBYID: `${Endpoint}/${prefix}`,
    API_GETALL: `${Endpoint}/${prefix}`,
    API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
    API_CREATE: `${Endpoint}/${prefix}/create`,
    API_DELETE: `${Endpoint}/${prefix}/delete`,
    API_UPDATE: `${Endpoint}/${prefix}/update`,
  
    INSERT_SUCCESS: 'SHIPPING_INSERT_SUCCESS',
    INSERT_FAILURE: 'SHIPPING_INSERT_FAILURE',

    UPDATE_SUCCESS: 'SHIPPING_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SHIPPING_UPDATE_FAILURE',
  
    DELETE_SUCCESS: 'SHIPPING_DELETE_SUCCESS',
    DELETE_FAILURE: 'SHIPPING_DELETE_FAILURE',
    
    GET_ALL: 'SHIPPING_GET_ALL',
    GET_DATA: 'SHIPPING_GET_DATA',
    GET_SELECT: 'SHIPPING_GET_SELECT',

    GET_FAILURE: 'SHIPPING_GET_FAILURE',
    CLEAR_DATA: 'SHIPPING_CLEAR_DATA'
}
