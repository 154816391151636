export const Endpoint = process.env.REACT_APP_API_URL;
export const prefix = 'role';
export const roleConstants = {
  API_SIGNIN: `${Endpoint}/auth/signin`,

  API_GETALL: `${Endpoint}/${prefix}`, //domain/role
  API_GETBYID: `${Endpoint}/${prefix}`, //domain/role/1
  API_GET_SALE: `${Endpoint}/${prefix}/getsales`,
  API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`, //domain/role/search
  API_CREATE: `${Endpoint}/${prefix}/create`, //domain/role/create
  API_DELETE: `${Endpoint}/${prefix}/delete`, //domain/role/delete
  API_UPDATE: `${Endpoint}/${prefix}/update`, //domain/role/update

  INSERT_SUCCESS: 'ROLE_INSERT_SUCCESS',
  INSERT_FAILURE: 'ROLE_INSERT_FAILURE',

  UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',

  DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
  DELETE_FAILURE: 'ROLE_DELETE_FAILURE',

  GET_ALL: 'ROLE_GET_ALL',
  GET_DATA: 'ROLE_GET_DATA',
  GET_SALE: 'ROLE_GET_SALE',
  GET_SELECT: 'ROLE_GET_SELECT',

  GET_FAILURE: 'ROLE_GET_FAILURE',
  CLEAR_DATA: 'ROLE_CLEAR_DATA',
};
