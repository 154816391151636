export const Endpoint = process.env.REACT_APP_API_URL;
export const prefix = 'customer';
export const customersConstants = {
  API_GETALL: `${Endpoint}/${prefix}`,
  API_GET_CUSTOMER_BY_ID: `${Endpoint}/${prefix}`,
  // API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
  GET_ALL: 'CUST_GET_ALL',
  GET_DATA: 'CUST_GET_DATA',
  GET_SELECT: 'CUST_GET_SELECT',
  GET_FAILURE: 'CUST_GET_FAILURE',
  CLEAR_DATA: 'CLEAR_DATA',
  GET_SELECT_CUSTOMER: 'CUST_GET_SELECT_CUSTOMER',
};
