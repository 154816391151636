export const Endpoint = process.env.REACT_APP_API_URL;
export const prefix = 'bank';
export const bankConstants = {
  API_GETBYID: `${Endpoint}/${prefix}`,
  API_GETALL: `${Endpoint}/${prefix}`,
  API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
  API_CREATE: `${Endpoint}/${prefix}/create`,
  API_DELETE: `${Endpoint}/${prefix}/delete`,
  API_UPDATE: `${Endpoint}/${prefix}/update`,

  INSERT_SUCCESS: 'BANK_INSERT_SUCCESS',
  INSERT_FAILURE: 'BANK_INSERT_FAILURE',

  UPDATE_SUCCESS: 'BANK_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BANK_UPDATE_FAILURE',

  DELETE_SUCCESS: 'BANK_DELETE_SUCCESS',
  DELETE_FAILURE: 'BANK_DELETE_FAILURE',

  GET_ALL: 'BANK_GET_ALL',
  GET_DATA: 'BANK_GET_DATA',
  GET_SELECT: 'BANK_GET_SELECT',

  GET_FAILURE: 'BANK_GET_FAILURE',
  CLEAR_DATA: 'BANK_CLEAR_DATA',
};
