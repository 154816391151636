export const Endpoint = process.env.REACT_APP_API_URL;
export const prefix = 'permission';
export const permissionConstants = {
  API_SIGNIN: `${Endpoint}/auth/signin`,

  API_GETALL: `${Endpoint}/${prefix}`, //domain/permission
  API_GETBYID: `${Endpoint}/${prefix}`,
  API_GET_SALE: `${Endpoint}/${prefix}/getsales`,
  API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`, //domain/permission/search
  API_CREATE: `${Endpoint}/${prefix}/create`, //domain/permission/create
  API_DELETE: `${Endpoint}/${prefix}/delete`, //domain/permission/delete
  API_UPDATE: `${Endpoint}/${prefix}/update`, //domain/permission/update

  INSERT_SUCCESS: 'PERMISSION_INSERT_SUCCESS',
  INSERT_FAILURE: 'PERMISSION_INSERT_FAILURE',

  UPDATE_SUCCESS: 'PERMISSION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PERMISSION_UPDATE_FAILURE',

  DELETE_SUCCESS: 'PERMISSION_DELETE_SUCCESS',
  DELETE_FAILURE: 'PERMISSION_DELETE_FAILURE',

  GET_ALL: 'PERMISSION_GET_ALL',
  GET_DATA: 'PERMISSION_GET_DATA',
  GET_SALE: 'PERMISSION_GET_SALE',
  GET_SELECT: 'PERMISSION_GET_SELECT',

  GET_FAILURE: 'PERMISSION_GET_FAILURE',
  CLEAR_DATA: 'PERMISSION_CLEAR_DATA',
};
