import { Constants as constants } from '../../../constants/dashboard.constants';

const initialState = null;

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ALL:
      return { ...state, allData: action.data };
    case constants.GET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case constants.GET_FAILURE:
      return { ...state, isError: true, error: action.error };

    default:
      return { ...state };
  }
};
export default dashboardReducer;
