export const Endpoint = process.env.REACT_APP_API_URL
export const prefix = 'order'
export const ordersConstants = {
  API_GETBYID: `${Endpoint}/${prefix}`,
  API_GETALL: `${Endpoint}/${prefix}`,
  API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
  API_CREATE: `${Endpoint}/${prefix}/create`,
  API_DELETE: `${Endpoint}/${prefix}/delete`,
  API_UPDATE: `${Endpoint}/${prefix}/update`,
  API_UPDATE_DELIVERY: `${Endpoint}/${prefix}/update/delivery`,
  API_UPDATE_STATUS: `${Endpoint}/${prefix}/update/status`,
  API_APPROVE: `${Endpoint}/${prefix}/approve`,
  API_APPROVE_MULTI: `${Endpoint}/${prefix}/approve/mulit`,
  API_FULL_TO_SUCCESS_MULTI: `${Endpoint}/${prefix}/fulltosuccess/mulit`,
  API_CLONE_ORDER: `${Endpoint}/${prefix}/clone`,

  INSERT_SUCCESS: 'ORDER_INSERT_SUCCESS',
  INSERT_FAILURE: 'ORDER_INSERT_FAILURE',

  UPDATE_SUCCESS: 'ORDER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ORDER_UPDATE_FAILURE',

  DELETE_SUCCESS: 'ORDER_DELETE_SUCCESS',
  DELETE_FAILURE: 'ORDER_DELETE_FAILURE',

  GET_ALL: 'ORDER_GET_ALL',
  GET_DATA: 'ORDER_GET_DATA',
  GET_SELECT: 'ORDER_GET_SELECT',

  GET_FAILURE: 'ORDER_GET_FAILURE',
  CLEAR_DATA: 'ORDER_CLEAR_DATA',
  ADD_PRODUCT: 'ADD_PRODCUT',
  LOADING: 'LOADING',

  SET_DISABLE_SHIPPING: 'SET_DISABLE_SHIPPING'
}
