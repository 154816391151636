export const Endpoint = process.env.REACT_APP_API_URL
export const prefix = 'product'
export const productsConstants = {    
    API_GETALL: `${Endpoint}/${prefix}`,
    // API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
  
    GET_ALL: 'PROD_GET_ALL',
    GET_DATA: 'PROD_GET_DATA',
    GET_SELECT: 'PROD_GET_SELECT',
    GET_FAILURE: 'PROD_GET_FAILURE'
}
