export const Endpoint = process.env.REACT_APP_API_URL;
export const prefix = 'users';
export const usersConstants = {
  API_SIGNIN: `${Endpoint}/auth/signin`,

  API_GETALL: `${Endpoint}/${prefix}`,
  API_GETBYID: `${Endpoint}/${prefix}`,
  API_GET_SALE: `${Endpoint}/${prefix}/getsales`,
  API_GETALL_SEARCH: `${Endpoint}/${prefix}/search`,
  API_CREATE: `${Endpoint}/${prefix}/create`,
  API_DELETE: `${Endpoint}/${prefix}/delete`,
  API_UPDATE: `${Endpoint}/${prefix}/update`,
  API_UPDATEPASSWORD: `${Endpoint}/${prefix}/updatepassword`,

  INSERT_SUCCESS: 'USERS_INSERT_SUCCESS',
  INSERT_FAILURE: 'USERS_INSERT_FAILURE',

  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  GET_ALL: 'USERS_GET_ALL',
  GET_DATA: 'USERS_GET_DATA',
  GET_SALE: 'USERS_GET_SALE',
  GET_SELECT: 'USERS_GET_SELECT',

  GET_FAILURE: 'USERS_GET_FAILURE',
  CLEAR_DATA: 'USERS_CLEAR_DATA',
};
