import mock from '../mock'
import { paginateArray } from '../utils'

const data = [
    {
      id: 1,
      name: 'Nim Express',
      value: 'Nim Express'
    },
    {
      id: 2,
      name: 'Kerry Express',
      value: 'Kerry Express'
    }
  ]


// GET ALL DATA
mock.onGet('/api/shipping/list/all-data').reply(config => {
  return [200, data]
})

// GET Updated DATA
mock.onGet('/api/shipping/list/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.name.toLowerCase().includes(queryLowered) ||
      item.value.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// POST: Add new user
// mock.onPost('/apps/shipping/add-shipping').reply(config => {
//   // Get event from post data
//   const user = JSON.parse(config.data)

//   const { length } = data.users
//   let lastIndex = 0
//   if (length) {
//     lastIndex = data.users[length - 1].id
//   }
//   user.id = lastIndex + 1

//   data.users.unshift(user)

//   return [201, { user }]
// })


// GET USER
// mock.onGet('/api/shipping/user').reply(config => {
//   const { id } = config
//   const user = data.users.find(i => i.id === id)
//   return [200, { user }]
// })

// DELETE: Deletes User
// mock.onDelete('/apps/shipping/delete').reply(config => {
//   // Get user id from URL
//   let userId = config.id

//   // Convert Id to number
//   userId = Number(userId)

//   const userIndex = data.users.findIndex(t => t.id === userId)
//   data.users.splice(userIndex, 1)

//   return [200]
// })
